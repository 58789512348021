import React from 'react'

const XIcon = () => {
  return (
    <svg fill="none" viewBox="0 0 27 27">
      <path
        fillRule="evenodd"
        d="M25.2929 26.7071l-25-25L1.7072.2929l25 25-1.4143 1.4142z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M26.7071 1.7071l-25 25L.293 25.2929l25-25 1.4142 1.4142z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default XIcon
