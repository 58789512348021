import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import XIcon from '../icons/XIcon'
// import {
//   disableBodyScroll,
//   enableBodyScroll,
//   clearAllBodyScrollLocks,
// } from 'body-scroll-lock'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import styles from './Lightbox.module.scss'

const Lightbox = ({ children, isVisible, setIsVisible }) => {
  // useEffect(() => {
  //   let scroller = document.scrollingElement
  //   let scrollbarWidth = window.innerWidth - scroller.clientWidth

  //   if (isVisible) {
  //     document.body.style.overflowY = 'hidden'
  //     document.body.style.paddingRight = `${scrollbarWidth}px`
  //   } else {
  //     document.body.style.overflowY = 'unset'
  //     document.body.style.paddingRight = '0'
  //   }
  // }, [isVisible])

  // Create a ref for the element that we still want to allowing scrolling on
  const contentRef = useRef(null)

  useEffect(() => {
    if (isVisible) {
      disablePageScroll(contentRef.current)
    } else {
      enablePageScroll(contentRef.current)
    }
  }, [isVisible])

  // FIXME Need to be able to focus on the Close button, or remove all the keyboard handlers that open the lightbox

  return (
    <div
      className={`${styles.lightboxOverlay} ${!isVisible && styles.hide}`}
      // ref={overlayRef}
      data-scroll-lock-fill-gap
    >
      <div className={`${styles.lightboxInnerContainer}`}>
        <button
          className={`${styles.closeBtn} ${!isVisible && styles.hide}`}
          onClick={() => setIsVisible(false)}
          aria-label="Close"
        >
          <XIcon />
        </button>
        <div className={`${styles.contentContainer}`} ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  )
}

Lightbox.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Lightbox
