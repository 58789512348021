import React from 'react'

const LinkIcon = () => {
	return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
      viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512"}}
    >
      <g id="XMLID_1_">
        <path id="XMLID_5_" d="M415.1,190.1l-29.8,29.8l-22.3-22.3l29.8-29.8c30.7-30.7,30.7-81.9,0-112.6s-81.9-30.7-112.6,0L184.3,151
          c-30.7,30.7-30.7,81.9,0,112.6c30.7,30.7,81.9,30.7,112.6,0l22.3,22.3c-21.4,21.4-50.2,32.6-79.1,32.6s-56.8-11.2-79.1-32.6
          c-43.7-43.7-43.7-114.4,0-157.3l95.8-95.8c43.7-43.7,114.4-43.7,157.3,0C457.9,76.5,457.9,147.2,415.1,190.1z M193.6,225.4
          l-3.7,3.7l22.3,22.3l3.7-3.7c30.7-30.7,81.9-30.7,112.6,0c30.7,30.7,30.7,81.9,0,112.6l-95.8,96.8c-30.7,30.7-81.9,30.7-112.6,0
          s-30.7-81.9,0-112.6l33.5-33.5l-22.3-22.3l-33.5,33.5C54,365.9,54,436.6,97.8,479.4c21.4,21.4,50.2,32.6,79.1,32.6
          c28.8,0,56.8-11.2,79.1-32.6l96.8-96.8c43.7-43.7,43.7-114.4,0-157.3C308,181.7,237.3,181.7,193.6,225.4z"/>
        </g>
    </svg>
	)
}

export default LinkIcon
