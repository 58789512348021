import { graphql, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import LinkIcon from '../icons/LinkIcon'
import styles from './ShareLinkButton.module.scss'

const ShareLinkButton = ({ className, linkPage, currentPage }) => {
  const [btnText, setBtnText] = useState('Share this')

  let destination = ''
  const currentURL = typeof window !== 'undefined' ? window.location.href : ''
  const root = currentURL.match(/^([a-z][a-z0-9+\-.]*:(\/\/[^/?#]+)?)/gm)
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          pathPrefix
        }
      }
    `
  )
  if (root !== null) {
    destination = root[0] + site.pathPrefix + '/' + linkPage
  }
  //console.log(destination);

  const linkBtnClicked = () => {
    navigator.clipboard.writeText(currentPage ? currentURL : destination)
    setBtnText('Link Copied!')
    setTimeout(() => {
      setBtnText('Share this')
    }, 2000)
  }

  return (
    <button
      className={`${styles.shareLinkBtn} ${className}`}
      onClick={() => linkBtnClicked()}
    >
      <p className={`h5`}>{btnText}</p>
      <LinkIcon />
    </button>
  )
}

export default ShareLinkButton
