import { Link, graphql } from 'gatsby'
import React, { useState } from 'react'

import CustomImg from '../components/global/CustomImg'
import Layout from '../components/global/Layout'
import Lightbox from '../components/global/Lightbox'
import SEO from '../components/global/Seo'
import ShareLinkButton from '../components/global/ShareLinkButton'
import TopNav from '../components/global/TopNav'
import styles from './illustration-gallery.module.scss'

const AnimationGallery = ({ data }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [lightboxIllustration, setLightboxIllustration] = useState(
    data.allStrapiIllustration.nodes[0]
  )

  const setLightboxVisible = illustration => {
    setLightboxOpen(true)
    setLightboxIllustration(illustration)
  }

  return (
    <Layout>
      <SEO
        title="Illustration Gallery"
        image={data.allStrapiIllustration.nodes[0].illustration_still}
      />
      <TopNav />
      <section className={`backgroundBlack pt128 pb64`}>
        <div className={`pageMargin1Col mb64`}>
          <h1 className={`h2`}>Illustrations</h1>
          <p className={`mb24`}>
            Witness significant moments across this history brought to life
            through the richly detailed illustrations crafted by artist Aria
            Villafranca.
          </p>
          <p className={`h5 textColorFuzzy`}>Tap an image to view</p>
        </div>
        <div
          // className={`sectionBottomMarginSmall sectionGrid1fr1fr pageMargin1Col`}
          className={`${styles.illustrationsGrid} pageMargin1Col`}
        >
          {data.allStrapiIllustration.nodes.map((illustration, index) => (
            <button
              style={{
                padding: '0',
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
              className={`textLeft`}
              onClick={() => setLightboxVisible(illustration)}
              aria-label="View fullscreen"
              key={index}
            >
              {/* <div className={`${styles.illustrationWrapper}`}></div> */}
              <CustomImg
                localFile={illustration.illustration_still.localFile}
                alt={illustration.illustration_still.alternativeText}
                captionData={{ caption: illustration.illustration_caption }}
                className={`${styles.illustrationWrapper}`}
              />
              <h2 className={`h5 pt16 pb8 ${styles.titleWrapper}`}>
                {illustration.illustration_title}
              </h2>
              <p className={`figcaption `}>
                {illustration.illustration_caption}
              </p>
            </button>
          ))}
        </div>
        <div className={`pageMargin1Col mt64`}>
          <hr className={`mt32 mb32 backgroundSilver`} />
          <div
            className={`flexTabletUp justifyContentBetween textCenter ml32 mr32`}
          >
            <Link to={`/`}>
              <p className={`h5 mt16 mb16`}>Return to Home</p>
            </Link>

            <Link to={`/profile-cards/`}>
              <p className={`h5 mt16 mb16`}>Profile Cards</p>
            </Link>

            <Link to={`/animation-gallery/`}>
              <p className={`h5 mt16 mb16`}>Animations</p>
            </Link>
          </div>
          <hr className={`mt32 backgroundSilver`} />
        </div>
        <Lightbox isVisible={lightboxOpen} setIsVisible={setLightboxOpen}>
          <CustomImg
            localFile={lightboxIllustration.illustration_still.localFile}
            alt={lightboxIllustration.illustration_still.alternativeText}
            captionData={{ caption: lightboxIllustration.illustration_caption }}
            className={`lightboxImg`}
          />
          <p className={`h6 lightboxCaption ${styles.lightboxCaption}`}>
            {lightboxIllustration.illustration_caption}
          </p>
          <ShareLinkButton
            linkPage={`illustration-gallery/${lightboxIllustration.illustration_slug}/`}
            className={`${styles.shareBtn}`}
          />
        </Lightbox>
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiIllustration(
      filter: { hide_in_gallery: { ne: true } }
      sort: { fields: section___section_code }
    ) {
      nodes {
        illustration_caption
        illustration_title
        illustration_slug
        illustration_still {
          alternativeText
          caption
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default AnimationGallery
